import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Publications = ({ data }) => {
    const { allMarkdownRemark } = data 
    const { frontmatter } = allMarkdownRemark.edges[0].node;
    const { sections } = frontmatter;

    const groupBy = (input, key) => {
      const group = input.reduce((acc, currentValue) => {
        let groupKey = currentValue[key];
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, {});
      return group
    };

    const renderGroupedPublications = (publications) => {
      const groups = []
      for (const [key, value] of Object.entries(groupBy(publications, 'outlet'))) {
        groups.push(
          <Container style={{marginBottom:16}}>
            <h3 style={{marginBottom:0}}>{key}</h3>
            {
              value.map(({ link, title }) => (
                <p><a href={link} target="_blank" rel="noreferrer">{title}</a></p>
              ))
            }
          </Container>
        )
      }
      return groups
    }

    return (
      <Layout>
        <SEO
          title="Publications"
          keywords={[`writer`, `rachel gerry`, `essays`, `critical theory`, `Toronto`]}
        />
        <div></div>
        <div className="rightAlign">
          {sections.map(({heading, publications, linkInTitle}) => 
            <section>
            <h2>{heading}</h2>
            {linkInTitle ?
              publications.map(({ link, title, quote }) => (
                <Container>
                  <h3>
                    <a href={link} target="_blank" rel="noreferrer">{title}</a>
                  </h3>
                    <p>{quote}</p>
                </Container>
              ))
            : 
              renderGroupedPublications(publications)
            }
            </section>
          )} 
        </div>
      </Layout>
    )
}
export default Publications

export const pageQuery = graphql`
  query PublicationsPageQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/publications"}}}) {
      edges {
        node {
          frontmatter {
            path
            sections {
              heading
              linkInTitle
              publications {
                link
                title
                quote
                outlet
              }
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  margin-bottom: 32px;
  & h3 {
    margin-bottom: 8px;
  }
`